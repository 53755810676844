import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Home from '../home/home';
import styles from './HomeTab.module.css';
import OrdersList from '../orders/OrdersList';
import Products from '../products/Products';
import AppIcon from '../../core/ui/AppIcon/AppIcon';
import {
  displayAppToolbar,
  hideAppToolbar,
} from '../../core/redux/actions/AppActions';
import ProductSearch from '../products/ProductSearch/ProductSearch';
import {
  fetchProducts,
  fetchCategories,
} from '../../core/redux/actions/ProductsActions';
import { translateLiteral } from '../../core/literals/Literal';
import * as safeAreaInsets from 'safe-area-insets';
import { TabContext } from './Tabs.context';
import * as queryString from 'query-string';
import history from '../../core/router/history';
import { Fragment } from 'react';

function SimpleBottomNavigation({
  itemsFetched,
  fetchCategories,
  fetchProducts,
  ...props
}) {
  const [value, setValue] = React.useState(0);
  const [openPack, setOpenPack] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState(null);
  const [needCallToolbar, setNeedCallToolbar] = React.useState(true);

  value && value != 3 ? props.displayAppToolbar() : props.hideAppToolbar();

  if (needCallToolbar) {
    setNeedCallToolbar(false);
  }

  useEffect(() => {
    if (!itemsFetched) {
      fetchCategories();
      fetchProducts();
    }
  }, [itemsFetched, fetchCategories, fetchProducts]);

  useEffect(() => {
    const params = queryString.parse(props.location.search);
    if (params.tab) {
      setValue(params.tab);
    }
  }, [props.location.search]);

  const productSearch = (
    <ProductSearch
      onChange={value => {
        setSearchValue(value);
      }}
    />
  );

  const TabActionLabel = ({ text, index, ...props }) => (
    <div style={{ color: index == value ? '#00abc8' : '#444' }}>{text}</div>
  );
  const actions = [
    {
      label: <TabActionLabel index={0} text={translateLiteral('menu.home')} />,
      icon: 'sea-waves',
      header: null,
      component: (
        <Home
          openProducts={openPack => {
            // setOpenPack(!!openPack);
            setValue(3);
          }}
        />
      ),
    },
    {
      label: (
        <TabActionLabel index={1} text={translateLiteral('menu.products')} />
      ),
      icon: 'producto',
      header: productSearch,
      component: <Products searchValue={searchValue} openPack={openPack} />,
    },
    {
      label: (
        <TabActionLabel index={2} text={translateLiteral('menu.orders')} />
      ),
      icon: 'calendar',
      header: null,
      component: (
        <OrdersList
          openProducts={() => {
            setValue(1);
          }}
        />
      ),
    },
    {
      label: (
        <TabActionLabel index={3} text={translateLiteral('menu.advantages')} />
      ),
      icon: 'aprobado',
      header: null,
      component: (
        <Fragment>
          <iframe
            src="https://blog.fontoasis.es/solucion-problemas-frecuentes/"
            width="100%"
            height="100%"
          />
        </Fragment>
      ),
    },
  ];

  const headerComponent = actions[value].header;
  const component = actions[value].component;

  return (
    <TabContext.Provider value={{ setTab: tabNumber => setValue(tabNumber) }}>
      <div className={styles.container}>
        {headerComponent && (
          <div className={styles.headerContainer}>{headerComponent}</div>
        )}
        <div className={styles.scrollContainer}>{component}</div>
        <BottomNavigation
          style={{
            width: '100%',
            height: `calc(${safeAreaInsets.bottom}px + var(--footer-tab-bar-height))`,
            position: 'fixed',
            bottom: 0,
            marginBottom: 0,
          }}
          value={value}
          onChange={(event, newValue) => {
            setOpenPack(false);
            history.push('/home?tab=' + newValue);
          }}
          showLabels
        >
          {actions.map((action, index) => (
            <BottomNavigationAction
              key={index}
              label={action.label}
              icon={
                <AppIcon
                  color={index == value ? '#00abc8' : '#444'}
                  size={22}
                  icon={action.icon}
                />
              }
            />
          ))}
        </BottomNavigation>
      </div>
    </TabContext.Provider>
  );
}

const mapStateToProps = state => {
  return {
    itemsFetched: state.products.itemsFetched,
  };
};

export default connect(mapStateToProps, {
  displayAppToolbar,
  fetchProducts,
  fetchCategories,
  hideAppToolbar,
})(SimpleBottomNavigation);
